import { useAppSelector } from '../../../store/hooks';
import { selectSidebarProductions } from '../../../store/mainReducer';
import { numberFormatFr } from '../../../utils';
import BarByChart from '../../../shared/BarByChart';
import SliderByYear from '../../../shared/SliderByYear';
import useTabletDetect from '../../../utils/useTabletDetect';

import Import from '../../../assets/images/import.svg';
import Export from '../../../assets/images/export.svg';
import { YEARS_B_REF } from '../../../utils/constants';
import styled from 'styled-components';

const StyledDiv = styled.div`
  font-size: 0.8rem;
  font-style: italic;
`;

const LeftAreaComponent = () => {
  const selectedProductions = useAppSelector(selectSidebarProductions);
  const isTablet = useTabletDetect();

  const currentProd = selectedProductions.filter(
    (elt: any) => elt.day === 'EJCS',
  )[0];

  return (
    <div className="sidebar-scroll">
      <div className="row px-4 ">
        <div className="col-12 mt-2 mb-3">
          <h5>{'Mix énergétique :'}</h5>
          <SliderByYear years={YEARS_B_REF} />
        </div>

        <div className="content p-2">
          {currentProd && (
            <div className="row">
              <div className="col-12 d-flex justify-content-between title">
                <h5 className="mb-0">{`${isTablet ? 'Prod.' : 'Production :'}`}</h5>
                <strong className="fs-strong">{`${numberFormatFr(
                  currentProd.total_prod,
                )} GW`}</strong>
              </div>
              <StyledDiv className="col-12 mb-2 sub-title">
                Production moyenne
              </StyledDiv>
              <div className="col-12">
                <BarByChart data={currentProd} />
              </div>
              <div className="col-12 mt-3 d-flex justify-content-between mb-3 title">
                <h5>{`${isTablet ? 'Conso.' : 'Consommation :'}`}</h5>
                <strong className="fs-strong">{`${numberFormatFr(
                  currentProd.total_conso,
                )} GW`}</strong>
              </div>
              {/* <div className="col-12">
              <AreaChart
                dataConso={currentProd.conso}
                currentHour={currentProd.hour}
              />
            </div> */}
              <div className="col-12  mb-2 d-flex justify-content-between title">
                <h5 className="mb-0">{`${isTablet ? 'Ech.' : 'Échanges :'}`}</h5>
                <div className="text-right" style={{ textAlign: 'right' }}>
                  <strong className="fs-strong">{`${currentProd.solde}`}</strong>
                  <br />
                  <span className="fs-span sub-title">{`${currentProd.sense}`}</span>
                </div>
              </div>
              <div className="col-12">
                <div className="mb-2 d-flex justify-content-between title">
                  <div className="text-start">
                    <img src={Import} alt="Import" />
                    <span className="mx-2">{`Import :`}</span>
                  </div>
                  <div className="fs-6">{`${currentProd.import}`}</div>
                </div>
                <div className="mb-2  d-flex justify-content-between title">
                  <div className="text-start">
                    <img src={Export} alt="Export" />
                    <span className="mx-2">{`Export :`}</span>
                  </div>
                  <div className="fs-6">{`${currentProd.export}`}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeftAreaComponent;
