import * as React from 'react';
import { DeckProps } from '@deck.gl/core/typed';
import { MapboxOverlay } from '@deck.gl/mapbox/typed';
import Map, { useControl, MapRef, LngLatBoundsLike } from 'react-map-gl';
import useDeviceWidth from '../../utils/useDeviceDetect';

import { MAPBOX_ACCESS_TOKEN } from '../../utils/constants';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import MapStyle from '../../assets/map-styles/style.json';
import { fromJS } from 'immutable';
import useGouletPathLayer from '../layers/goulet/useGouletPathLayer';
import LegendModal from '../initMap/map-area/legend';
import useGouletLayer from '../layers/goulet/useGouletLayer';
import useEuropeLayer from '../layers/config/useEuropeLayer';
import useFranceBorderLayer from '../layers/config/useFranceBorderLayer';
import useWaterLayer from '../layers/config/useWaterLayer';
import useRegionsLayer from '../layers/config/useRegionsLayer';

import {
  selectModalSimulator,
  setCurrentZoom,
  toggleModalSimulator,
  selectCurrentZoom,
  selectCurrentYear,
  setSegments,
  setCurrentGouletZone,
} from '../../store/mainReducer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

import { getSegmentsByYear } from '../../services';
import useConsoGouletLayer from '../layers/goulet/useConsoGouletLayer';
import PointInterestMap from '../pointIn';
import useProdGouletLayer from '../layers/goulet/useProdGouletLayer';
import useFluxGouletLayer from '../layers/goulet/useFluxGouletLayer';
import useExchangeAllemagneGouletLayer from '../layers/goulet/useExchangeAllemagneGouletLayer';
import useExchangeSuisseGouletLayer from '../layers/goulet/useExchangeSuisseGouletLayer';
import useDiffusGouletLayer from '../layers/goulet/useDiffusGouletLayer';
import useExchangeAngleterreGouletLayer from '../layers/goulet/useExchangeAngleterreGouletLayer';
import useExchangeEspagneGouletLayer from '../layers/goulet/useExchangeEspagneGouletLayer';
import useExchangeItalieGouletLayer from '../layers/goulet/useExchangeItalieGouletLayer';
import useExchangeBelgiqueGouletLayer from '../layers/goulet/useExchangeBelgiqueGouletLayer';

import InitZoom from '../../assets/images/init-zoom.svg';
import useGouletCustomTripsLayer from '../layers/goulet/useGouletCustomTripsLayer';

const StyledDiv = styled.div`
  .btn-cf1 {
    position: relative;
    background: #e9f7fc;
    color: #004d66;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
    text-decoration: none;
  }
  .position-absolute {
    z-index: 11;
  }
`;

function DeckGLOverlay(props: DeckProps) {
  const deck = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  deck.setProps(props);
  return null;
}

export default function GouletMapComponent() {
  const isMobile = useDeviceWidth();

  const selectedModel = useAppSelector(selectModalSimulator);
  const currentZoom = useAppSelector(selectCurrentZoom);
  const currentYear = useAppSelector(selectCurrentYear);

  const dispatch = useAppDispatch();

  const franceBounds: LngLatBoundsLike = [
    [-3.4196748156955437, 42.27686482162082],
    [7.369538545993322, 51.07137605515297],
  ];

  const mapRef = React.useRef<MapRef>() as React.Ref<MapRef>;

  const gouletPathLayer = useGouletPathLayer();
  const franceBorder = useFranceBorderLayer();
  const europeLayer = useEuropeLayer();
  const waterLayer = useWaterLayer();
  const regionsLayer = useRegionsLayer();
  const gouletsLayer = useGouletLayer();
  const consoGouletLayer = useConsoGouletLayer();
  const prodGouletLayer = useProdGouletLayer();
  const fluxGouletLayer = useFluxGouletLayer();
  const tripsLayer = useGouletCustomTripsLayer();
  const exchangeAllemagneGouletLayer = useExchangeAllemagneGouletLayer();
  const exchangeSuisseGouletLayer = useExchangeSuisseGouletLayer();
  const diffusGouletLayer = useDiffusGouletLayer();
  const exchangeAngleterreGouletLayer = useExchangeAngleterreGouletLayer();
  const exchangeEspagneGouletLayer = useExchangeEspagneGouletLayer();
  const exchangeItalieGouletLayer = useExchangeItalieGouletLayer();
  const exchangeBelgiqueGouletLayer = useExchangeBelgiqueGouletLayer();

  const handleInitZoom = () => {
    dispatch(setCurrentZoom(null));

    // change network files when clicking a point of interest
    const data_segment = getSegmentsByYear(currentYear);
    dispatch(setSegments(data_segment));

  };

  const handleCloseLegend = (): void => {
    dispatch(toggleModalSimulator(false));
  };
  const handleShowLegend = (): void => {
    dispatch(toggleModalSimulator(true));
  };

  const handleCloseGoulet = (): void => {
    dispatch(setCurrentGouletZone(''));
  };

  return (
    <>
      <Map
        initialViewState={{
          longitude: 2.5,
          latitude: 46.5,
          bounds: franceBounds,
          fitBoundsOptions: { padding: isMobile ? 0 : 30 },
        }}
        mapStyle={fromJS(MapStyle) as any}
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        ref={mapRef}
        dragRotate={false}
        dragPan={false}
        touchZoomRotate={false}
        doubleClickZoom={false}
        scrollZoom={false}
        // cursor="auto"
        style={{ position: 'relative' }}
      >
        <DeckGLOverlay
          layers={[
            ...consoGouletLayer,
            ...regionsLayer,
            ...waterLayer,
            ...europeLayer,
            ...franceBorder,
            ...gouletPathLayer,
            ...tripsLayer,
            // ...pathGouletLayer,
            ...gouletsLayer,

            ...exchangeAllemagneGouletLayer,
            ...exchangeSuisseGouletLayer,
            ...exchangeAngleterreGouletLayer,
            ...exchangeEspagneGouletLayer,
            ...exchangeItalieGouletLayer,
            ...exchangeBelgiqueGouletLayer,
            ...diffusGouletLayer,
                 
            ...prodGouletLayer,
            ...fluxGouletLayer,
          ]}
        />
         <Button
          className="btn-cf position-absolute p-0"
          style={{
            top: '10px',
            left: '10px',
            width: 'auto',
            zIndex: isMobile ? 11 : 1500,
          }}
          variant="secondary"
          onClick={handleCloseGoulet}
        >
          <img src={InitZoom} alt="" width="32px" height="32px" />
        </Button>

        <StyledDiv
          className="position-absolute"
          style={{
            bottom: '10px',
            left: isMobile ? '1px' : '10px',
            zIndex: isMobile ? 11 : 1500,
          }}
        >
          {!isMobile ? (
            <Button
              className="btn-cf"
              variant="link"
              onClick={handleShowLegend}
            >
              <span className="icon icon-button-legend">
                <span className="path1"></span>
                <span className="path2"></span>
              </span>
            </Button>
          ) : (
            <Button
              className="btn-cf1"
              variant="link"
              onClick={handleShowLegend}
            >
              {'Légende'}
            </Button>
          )}
        </StyledDiv>
      </Map>
      <LegendModal show={selectedModel} handleClose={handleCloseLegend} />
      {currentZoom !== null && (
        <PointInterestMap
          currentZoom={currentZoom}
          handleClose={handleInitZoom}
        />
      )}
    </>
  );
}
