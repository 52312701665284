import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { YEARS_A_REF } from '../../utils/constants';
import {
  selectCurrentPointActive,
  selectCurrentYear,
  selectInitStateViewPointIn,
  setAdaptationPointIn,
  setCurrentYear,
} from '../../store/mainReducer';
import RangeSlider from 'react-bootstrap-range-slider';
import { getInitStatView } from '../../services';

interface ContentProps {
  handleUpdate: (state: any, newYear: string) => void;
}

const SliderPointIn: React.FC<ContentProps> = ({ handleUpdate }) => {
  const dispatch = useAppDispatch();
  const currentYear = useAppSelector(selectCurrentYear);
  const initStateViewPointIn = useAppSelector(selectInitStateViewPointIn);
  const currentPointActive = useAppSelector(selectCurrentPointActive);

  const [viewState, setViewState] = useState(initStateViewPointIn);

  // SLIDER BY ZOOM
  const handleZoomChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newZoom = parseFloat(event.target.value);
      let year = currentYear;

      // UPDATE INIT VIEW
      if (currentPointActive === 'SSFOS') {
        if (newZoom === 8) {
          year = `YEAR_2024`;
        } else if (newZoom === 9.8) {
          year = `YEAR_2030`;
        }
      } else if (currentPointActive === 'SSHAVRE') {
        if (newZoom === 9) {
          year = `YEAR_2024`;
        } else if (newZoom === 9.8) {
          year = `YEAR_2030`;
        }
      }

      const state = getInitStatView(currentPointActive, year);
      setViewState((prevState: any) => ({
        ...prevState,
        zoom: newZoom,
        latitude: state.latitude,
        longitude: state.longitude,
        transitionDuration: 600,
      }));
      dispatch(setAdaptationPointIn(false));
      dispatch(setCurrentYear(year));
      handleUpdate(state, year);
    },
    [currentPointActive, currentYear, dispatch, handleUpdate],
  );

  return (
    <>
      <div className="col-12 mt-2">
        <h5 className="mb-0">{'Mix énergétique :'}</h5>
      </div>
      <div className="btn-year">
        <div className="slider">
          <div className="d-flex justify-content-between">
            {YEARS_A_REF.map((item: any, index: number) => {
              return (
                <small key={index} className="text-muted">
                  {item}
                </small>
              );
            })}
          </div>
          <RangeSlider
            min={currentPointActive === 'SSFOS' ? 8 : 9}
            max={currentPointActive === 'SSFOS' ? 9.8 : 9.8}
            step={currentPointActive === 'SSFOS' ? 1.8 : 0.8}
            value={viewState.zoom}
            onChange={handleZoomChange}
            tooltip="off" // Disable the built-in tooltip, you can enable if you prefer
          />
        </div>
      </div>
    </>
  );
};

export default SliderPointIn;
