import { useAppSelector } from '../../../store/hooks';
import {
  selectCurrentPointActive,
  selectCurrentYear,
  selectPostPointInByYearAndClick,
} from '../../../store/mainReducer';

import { TextLayer, IconLayer } from '@deck.gl/layers/typed';

import Poste225 from '../../../assets/images/points/Poste225.svg';
import Poste400 from '../../../assets/images/points/Poste400.svg';

import Poste225_zoom from '../../../assets/images/points/Poste225_zoom.svg';
import PosteSature from '../../../assets/images/points/left/PosteSature.svg';
import PosteRoso from '../../../assets/images/points/PosteRoso.svg';
import { PostTPointFeature } from '../../../models/ZoomFeature';

const getPositionByName = (idr: string): any => {
  switch (idr) {
    case 'SANDO':
      return [70, -50];
    case 'P.JER':
      return [-10, -70];
    case 'ROUGE':
      return [80, -100];
      case 'ROQUEROUSSE':
        return [20, -40];
    case 'NOROI':
      return [0, -140];
    default:
      return [20, -20];
  }
};

const getIconeByType = (type: string, year: string, zone: string, color?: any): any => {
  if (year === 'YEAR_2024') {
    switch (type) {
      case '400':
        return {
          url: Poste400,
          width: 14,
          height: 14,
        };
      case '225':
        return {
          url: Poste225,
          width: 10,
          height: 10,
        };
    }
  } else if (year === 'YEAR_2030') {
    switch (type) {
      case '400':
        if (zone === 'SSHAVRE') {
          return {
            url: color === '255,0,0' ? PosteSature : Poste225_zoom,
            width: 14,
            height: 14,
          };
        } else {
          return {
            url: Poste225_zoom,
            width: 14,
            height: 14,
          };
        }
      case '225':
        if (zone === 'SSHAVRE') {
          return {
            url: color === '255,0,0' ? PosteSature : Poste225_zoom,
            width: 10,
            height: 10,
          };
        } else {
          return {
            url: Poste225_zoom,
            width: 10,
            height: 10,
          };
        }
    }
  } else {
    switch (type) {
      case '400':
        return {
          url: color === '83,202,209' ? PosteRoso : Poste225_zoom,
          width: 14,
          height: 14,
        };
      case '225':
        return {
          url: color === '83,202,209' ? PosteRoso : Poste225_zoom,
          width: 10,
          height: 10,
        };
    }
  }
};

const usePostPointLayer = () => {
  const postPointIn = useAppSelector(selectPostPointInByYearAndClick);
  const currentYear = useAppSelector(selectCurrentYear);
  const currentPointActive = useAppSelector(selectCurrentPointActive);

  const namePostPoint = new TextLayer({
    id: 'post-point-layer',
    data: postPointIn.filter(
      (item) => item.properties.STYLE === 'avec-etiquette',
    ),
    getText: (d: any) => d.properties.ADR_SITE,
    getPosition: (d) => d.positionName as any,
    getAlignmentBaseline: 'center',
    getColor: (d: PostTPointFeature) => [255,255,255],
    getBackgroundColor: (d: PostTPointFeature) => [
      d.properties.COLOR[0] as number,
      d.properties.COLOR[1] as number,
      d.properties.COLOR[2] as number,
      120,
    ],
    backgroundPadding: [4, 4, 4, 4],
    pickable: true,
    getSize: 12,
    fontWeight: 'bold',
    getTextAnchor: 'middle',
    background: true,
    getPixelOffset: (d: any) => d.properties.ZONE ==='SSHAVRE' ? [0, -0] : [40, -30],
    characterSet: 'auto',
    outlineWidth: 4,
    getBorderWidth: 0,
    fontFamily: 'Nunito Sans',
    billboard: true,
    visible: true,
  });

  const iconePoint = new IconLayer({
    id: 'icon-layer-1',
    data: postPointIn,
    getIcon: (d: any) =>
      getIconeByType(d.properties.SOUS_TYPE, currentYear, currentPointActive, `${d.properties.COLOR}`),
    getSize: (d: any) =>
      getIconeByType(d.properties.SOUS_TYPE, currentYear, currentPointActive, `${d.properties.COLOR}`)
        .height,
    getPosition: (d: any) => {
      return d.position;
    },
    getColor: [255, 255, 0],
    getPixelOffset: [0, 0],
    pickable: true,
    visible: true,
  });

  return [namePostPoint, iconePoint];
};

export default usePostPointLayer;
