import { PathLayer } from '@deck.gl/layers/typed';
import { useAppSelector } from '../../../store/hooks';
import {
  selectCategoryActivePointIn,
  selectCurrentYear,
  selectLignesPointIn,
} from '../../../store/mainReducer';
import { CURRENT_YEAR_VALUES } from '../../../utils/enums';
import { getLigneActivePointSize } from '../../../utils';

const useLignesPointLayer = () => {
  const currentYear = useAppSelector(selectCurrentYear);
  const lignesPointIn = useAppSelector(selectLignesPointIn);
  const catActivePointIn = useAppSelector(selectCategoryActivePointIn);

  const currentYearValue =
    CURRENT_YEAR_VALUES[currentYear as keyof typeof CURRENT_YEAR_VALUES];

  // pas de click 2024
  const paths = new PathLayer({
    id: 'paths',
    data: lignesPointIn.filter(
      (item: any) =>
        item.properties.ANNEE === currentYearValue &&
        item.properties.CLICK === catActivePointIn,
    ),
    getPath: (d: any) => d.position,
    getWidth: (d: any) => d.size,
    widthUnits: 'pixels',
    getColor: (d: any) => d.color,
    visible: true,
  });

  const paths_active = new PathLayer({
    id: 'path-active',
    data: lignesPointIn.filter(
      (item: any) =>
        item.properties.ANNEE === currentYearValue &&
        item.properties.CLICK === catActivePointIn &&
        (item.properties.STYLE === '400-2' ||
          item.properties.STYLE === '400-4' ||
          item.properties.STYLE === '400-6' ||
          item.properties.STYLE === '400-7' ||
          item.properties.STYLE === '225-2' ||
          item.properties.STYLE === '225-4' ||
          item.properties.STYLE === '400-5' ||
          item.properties.STYLE === '225-6'),
    ),
    getPath: (d: any) => d.position,
    getWidth: (d: any) => getLigneActivePointSize(d.properties.STYLE),
    widthUnits: 'pixels',
    getColor: (d: any) => d.color,
    visible: true,
    opacity: 0.1,
  });

  return [paths, paths_active];
};

export default useLignesPointLayer;
