import { METEO_REF_B_LABELS } from '../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  selectCurrentYear,
  selectStateClimate,
  setGouletSegments,
  setSites,
  setStateClimate,
} from '../../store/mainReducer';
import { isValidClimate } from '../../utils';

import MultipleDuelSwitch from '../../shared/MultipleDuelSwitch';
import { getGouletSegments, getSitesByDay } from '../../services';
import ItemLegendGoulet from './ItemLegendGoulet';
import SliderByYearDay from '../../shared/SliderByYearDay';

const LeftGouletComponent = () => {
  const dispatch = useAppDispatch();

  const currentYear = useAppSelector(selectCurrentYear);
  const selectedClimate = useAppSelector(selectStateClimate);

  const handleSwitchClimateRefB = (newClimate: string) => {
    if (isValidClimate(newClimate)) {
      dispatch(setStateClimate(newClimate));

      // update segement with climate
      const data_segment = getGouletSegments(currentYear, newClimate);
      dispatch(setGouletSegments(data_segment));

      const sites = getSitesByDay(currentYear, newClimate);
      dispatch(setSites(sites));
    }
  };

  return (
    <div className="row px-4 ">
      <div className="col-12 mt-2">
        <h5>{'Mix énergétique :'}</h5>
      </div>

      <div className="btn-year">
        <SliderByYearDay years={[2030, 2035, 2040]} />
      </div>

      <div className="col-12 mb-3">
        <div className="col-12 mb-1">
          <h5>{'Conditions météo'}</h5>
        </div>

        <MultipleDuelSwitch
          radios={METEO_REF_B_LABELS}
          nameToggle={'EJCS'}
          selectedToggle={
            selectedClimate === 'EJCS'
              ? METEO_REF_B_LABELS[0].value
              : METEO_REF_B_LABELS[1].value
          }
          onProductTypeChange={handleSwitchClimateRefB}
        />
      </div>

      <ItemLegendGoulet />
    </div>
  );
};

export default LeftGouletComponent;
