import { IconLayer } from '@deck.gl/layers/typed';

import {
  selectCurrentGouletZone,
  selectCurrentYear,
  selectGouletSymbols,
  selectStateClimate,
} from '../../../store/mainReducer';
import { useAppSelector } from '../../../store/hooks';
import { GouletSymbolFeature } from '../../../models/GouletSymbolFeature';
import { splitYear } from '../../../utils';

import Echange_Belgique from '../../../assets/images/goulet/Echange_Belgique.svg';

const useExchangeBelgiqueGouletLayer = () => {
  const currentYear = useAppSelector(selectCurrentYear);
  const currentGouletZone = useAppSelector(selectCurrentGouletZone);
  const currentClimat = useAppSelector(selectStateClimate);
  const gouletSymbol = useAppSelector(selectGouletSymbols);

  const getSymbolData = () => {
    return gouletSymbol.filter((item: GouletSymbolFeature) => {
      const { categorie } = item.properties;
      const mix = splitYear(currentYear).toString();
      const prefix = `${currentGouletZone}_${mix}_${currentClimat}_echange_belgique`;
      return categorie.startsWith(prefix);
    });
  };



  const layer = new IconLayer({
    id: 'echange-belgue-layer',
    data: getSymbolData(),
    getIcon: (d: any) => {
      return {
        url: Echange_Belgique,
        width: 28,
        height: 32,
      }
    },
    getPosition: (d: any) => {
      return d.position;
    },
    getSize: (d: any) => 32,
    getColor: () => [255, 255, 255, 255],
    pickable: true,
  });

  return [layer];
};

export default useExchangeBelgiqueGouletLayer;
