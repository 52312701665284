import { IconLayer } from '@deck.gl/layers/typed';

import {
  selectCurrentGouletZone,
  selectCurrentYear,
  selectGouletSymbols,
  selectStateClimate,
} from '../../../store/mainReducer';
import { useAppSelector } from '../../../store/hooks';
import { GouletSymbolFeature } from '../../../models/GouletSymbolFeature';
import { splitYear } from '../../../utils';

import B_2030_EJCS_diffus from '../../../assets/images/symbols/B_2030_EJCS_diffus.svg';
import B_2035_EJCS_diffus from '../../../assets/images/symbols/B_2035_EJCS_diffus.svg';
import B_2040_EJCS_diffus from '../../../assets/images/symbols/B_2040_EJCS_diffus.svg';

import C_2030_EJCS_diffus from '../../../assets/images/symbols/C_2030_EJCS_diffus.svg';
import C_2035_EJCS_diffus from '../../../assets/images/symbols/C_2035_EJCS_diffus.svg';
import C_2040_EJCS_diffus from '../../../assets/images/symbols/C_2040_EJCS_diffus.svg';

import E_2030_EJCS_diffus from '../../../assets/images/symbols/E_2030_EJCS_diffus.svg';
import E_2035_EJCS_diffus from '../../../assets/images/symbols/E_2035_EJCS_diffus.svg';
import E_2040_EJCS_diffus from '../../../assets/images/symbols/E_2040_EJCS_diffus.svg';

import I_2040_HNVC_diffus_eolien_4 from '../../../assets/images/symbols/I_2040_HNVC_diffus_eolien_4.svg';
import I_2030_HNVC_diffus_eolien_4 from '../../../assets/images/symbols/I_2030_HNVC_diffus_eolien_4.svg';
import I_2035_HNVC_diffus_eolien_4 from '../../../assets/images/symbols/I_2035_HNVC_diffus_eolien_4.svg';

import MerDuNord2040 from '../../../assets/images/goulet/MerDuNord2040.svg';
import Manche2040 from '../../../assets/images/goulet/Manche2040.svg';
import MerDuNord2035 from '../../../assets/images/goulet/MerDuNord2035.svg';
import FaçadeAtlantique2035 from '../../../assets/images/goulet/FaçadeAtlantique2035.svg';
import FaçadeAtlantique2040 from '../../../assets/images/goulet/FaçadeAtlantique2040.svg';

const useDiffusGouletLayer = () => {
  const currentYear = useAppSelector(selectCurrentYear);
  const currentGouletZone = useAppSelector(selectCurrentGouletZone);
  const currentClimat = useAppSelector(selectStateClimate);
  const gouletSymbol = useAppSelector(selectGouletSymbols);

  const getSymbolData = () => {
    return gouletSymbol.filter((item: GouletSymbolFeature) => {
      const { categorie } = item.properties;
      const mix = splitYear(currentYear).toString();
      const prefix = `${currentGouletZone}_${mix}_${currentClimat}_diffus`;
      return categorie.startsWith(prefix);
    });
  };

  const getSymbolIcon = (categorie: any): any => {
    switch (categorie) {
      case 'B_2030_EJCS_diffus':
        return {
          url: B_2030_EJCS_diffus,
          width: window.innerWidth > 1480 ? 430 : 380,
          height: window.innerWidth > 1480 ? 418 : 360,
        };
      case 'B_2035_EJCS_diffus':
        return {
          url: B_2035_EJCS_diffus,
          width: window.innerWidth > 1480 ? 430 : 380,
          height: window.innerWidth > 1480 ? 418 : 360,
        };
      case 'B_2040_EJCS_diffus':
        return {
          url: B_2040_EJCS_diffus,
          width: window.innerWidth > 1480 ? 430 : 380,
          height: window.innerWidth > 1480 ? 418 : 360,
        };
      case 'B_2040_HNVC_diffus_eolien_1':
        return {
          url: FaçadeAtlantique2040,
          width: 207,
          height: 210,
        };
      case 'B_2040_HNVC_diffus_eolien_2':
        return {
          url: Manche2040,
          width: 210,
          height: 119,
        };
      case 'B_2040_HNVC_diffus_eolien_3':
        return {
          url: MerDuNord2040,
          width: 260,
          height: 229,
        };
      case 'B_2035_HNVC_diffus_eolien_1':
        return {
          url: FaçadeAtlantique2035,
          width: 207,
          height: 210,
        };
      case 'B_2035_HNVC_diffus_eolien_3':
        return {
          url: MerDuNord2035,
          width: 260,
          height: 229,
        };
      case 'C_2030_EJCS_diffus':
        return {
          url: C_2030_EJCS_diffus,
          width: 330,
          height: 303,
        };
      case 'C_2035_EJCS_diffus':
        return {
          url: C_2035_EJCS_diffus,
          width: 329,
          height: 303,
        };
      case 'C_2040_EJCS_diffus':
        return {
          url: C_2040_EJCS_diffus,
          width: window.innerWidth > 1480 ? 329 : 290,
          height: window.innerWidth > 1480 ? 303 : 280,
        };
      case 'C_2040_HNVC_diffus_eolien_1':
        return {
          url: FaçadeAtlantique2040,
          width: 207,
          height: 210,
        };
      case 'C_2040_HNVC_diffus_eolien_2':
        return {
          url: Manche2040,
          width: 210,
          height: 119,
        };
      case 'C_2040_HNVC_diffus_eolien_3':
        return {
          url: MerDuNord2040,
          width: 260,
          height: 229,
        };
      case 'C_2035_HNVC_diffus_eolien_1':
        return {
          url: FaçadeAtlantique2035,
          width: 207,
          height: 210,
        };
      case 'C_2035_HNVC_diffus_eolien_3':
        return {
          url: MerDuNord2035,
          width: 260,
          height: 229,
        };
      case 'E_2030_EJCS_diffus':
        return {
          url: E_2030_EJCS_diffus,
          width: 229,
          height: 343,
        };
      case 'E_2035_EJCS_diffus':
        return {
          url: E_2035_EJCS_diffus,
          width: 228,
          height: 342,
        };
      case 'E_2040_EJCS_diffus':
        return {
          url: E_2040_EJCS_diffus,
          width: 228,
          height: 342,
        };

      case 'E_2040_HNVC_diffus_eolien_1':
        return {
          url: FaçadeAtlantique2040,
          width: 207,
          height: 210,
        };
      case 'E_2040_HNVC_diffus_eolien_2':
        return {
          url: Manche2040,
          width: 210,
          height: 119,
        };
      case 'E_2040_HNVC_diffus_eolien_3':
        return {
          url: MerDuNord2040,
          width: 260,
          height: 229,
        };
      case 'E_2035_HNVC_diffus_eolien_1':
        return {
          url: FaçadeAtlantique2035,
          width: 207,
          height: 210,
        };
      case 'E_2035_HNVC_diffus_eolien_3':
        return {
          url: MerDuNord2035,
          width: 260,
          height: 229,
        };

      case 'I_2040_HNVC_diffus_eolien_1':
        return {
          url: FaçadeAtlantique2040,
          width: 207,
          height: 210,
        };
      case 'I_2040_HNVC_diffus_eolien_2':
        return {
          url: Manche2040,
          width: 210,
          height: 119,
        };
      case 'I_2040_HNVC_diffus_eolien_3':
        return {
          url: MerDuNord2040,
          width: 260,
          height: 229,
        };
      case 'I_2035_HNVC_diffus_eolien_1':
        return {
          url: FaçadeAtlantique2035,
          width: 207,
          height: 210,
        };
      case 'I_2035_HNVC_diffus_eolien_3':
        return {
          url: MerDuNord2035,
          width: 260,
          height: 229,
        };
      case 'I_2040_HNVC_diffus_eolien_4':
        return {
          url: I_2040_HNVC_diffus_eolien_4,
          width: 423,
          height: 444,
        };
      case 'I_2030_HNVC_diffus_eolien_4':
        return {
          url: I_2030_HNVC_diffus_eolien_4,
          width: 423,
          height: 444,
        };
      case 'I_2035_HNVC_diffus_eolien_4':
        return {
          url: I_2035_HNVC_diffus_eolien_4,
          width: 423,
          height: 444,
        };
      default:
        return;
    }
  };

  const getOffsetIcon = (categorie: string): any => {
    switch (categorie) {
      case 'B_2030_EJCS_diffus':
        return [30, 30];
      case 'B_2035_EJCS_diffus':
        return [30, 30];
      case 'B_2040_EJCS_diffus':
        return [30, 30];
      case 'B_2040_HNVC_diffus_eolien_1':
        return [-34, -31];
      case 'B_2040_HNVC_diffus_eolien_2':
        return [0, -7];
      case 'B_2040_HNVC_diffus_eolien_3':
        return [12, -12];
      case 'B_2035_HNVC_diffus_eolien_1':
        return [-34, -31];
      case 'B_2035_HNVC_diffus_eolien_3':
        return [12, -12];
      case 'C_2030_EJCS_diffus':
        return [-3, -15];
      case 'C_2035_EJCS_diffus':
        return [-3, -15];
      case 'C_2040_EJCS_diffus':
        return [-3, -15];
      case 'E_2030_EJCS_diffus':
        return [-10, -15];
      case 'E_2035_EJCS_diffus':
        return [-10, -15];
      case 'E_2040_EJCS_diffus':
        return [-10, -15];
      case 'C_2040_HNVC_diffus_eolien_1':
        return [-34, -31];
      case 'C_2040_HNVC_diffus_eolien_2':
        return [0, -7];
      case 'C_2040_HNVC_diffus_eolien_3':
        return [12, -12];
      case 'C_2035_HNVC_diffus_eolien_1':
        return [-34, -31];
      case 'C_2035_HNVC_diffus_eolien_3':
        return [12, -12];
      case 'E_2040_HNVC_diffus_eolien_1':
        return [-34, -31];
      case 'E_2040_HNVC_diffus_eolien_2':
        return [0, -7];
      case 'E_2040_HNVC_diffus_eolien_3':
        return [12, -12];
      case 'E_2035_HNVC_diffus_eolien_1':
        return [-34, -31];
      case 'E_2035_HNVC_diffus_eolien_3':
        return [12, -12];
      case 'I_2040_HNVC_diffus_eolien_1':
        return [-34, -31];
      case 'I_2040_HNVC_diffus_eolien_2':
        return [0, -7];
      case 'I_2040_HNVC_diffus_eolien_3':
        return [12, -12];
      case 'I_2035_HNVC_diffus_eolien_1':
        return [-34, -31];
      case 'I_2035_HNVC_diffus_eolien_3':
        return [12, -12];
      case 'I_2030_HNVC_eolien_diffus_4':
        return [-10, -17];
      case 'I_2035_HNVC_eolien_diffus_4':
        return [-10, -17];
      case 'I_2040_HNVC_eolien_diffus_4':
        return [-10, -17];
      default:
        return;
    }
  };
  

  const layer = new IconLayer({
    id: 'diffus-layer',
    data: getSymbolData(),
    getIcon: (d: any) => getSymbolIcon(d.properties.categorie),
    getPosition: (d: any) => {
      return d.position;
    },
    opacity: 1,
    getSize: (d: any) => getSymbolIcon(d.properties.categorie).height || 1,
    // getColor: () => [255, 255, 255, 255],
    getPixelOffset: (d: any) => getOffsetIcon(d.properties.categorie),
    pickable: true,
    sizeUnits: 'pixels', // Makes size responsive to zoom
    sizeScale: 1, // Adjust as needed
  });

  return [layer];
};

export default useDiffusGouletLayer;
