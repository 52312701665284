import * as React from 'react';
import { useAppSelector } from '../../../store/hooks';
import { selectGouletSegmentsByZone } from '../../../store/mainReducer';
import CustomTripsLayer from '../../../shared/CustomTripsLayer';
import { GouletSegmentFeature } from '../../../models/GouletSegmentFeature';
import { TripsLayer } from '@deck.gl/geo-layers';

const useGouletCustomTripsLayer = (): Array<any> => {
  const visibleSegmentTrips = useAppSelector(selectGouletSegmentsByZone);

  const [time_s1, setTime_s1] = React.useState<number>(0);
  const [time_s2, setTime_s2] = React.useState<number>(0);
  const [time_s3, setTime_s3] = React.useState<number>(0);
  const [time_s4, setTime_s4] = React.useState<number>(0);
  const loopLength = 1000;
  const animationSpeed_s1 = 3;
  const animationSpeed_s2 = 4.5;
  const animationSpeed_s3 = 6;
  const animationSpeed_s4 = 8;
  const [animation] = React.useState<{ id: number }>({ id: 0 });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const animate = () => {
    setTime_s1((t) => (t + animationSpeed_s1) % loopLength);
    setTime_s2((t) => (t + animationSpeed_s2) % loopLength);
    setTime_s3((t) => (t + animationSpeed_s3) % loopLength);
    setTime_s4((t) => (t + animationSpeed_s4) % loopLength);
    animation.id = window.requestAnimationFrame(animate);
  };

  const trips_s1 = new CustomTripsLayer<GouletSegmentFeature>({
    id: 'flux-1',
    data: visibleSegmentTrips.filter(
      (elt: GouletSegmentFeature) => Number(elt.properties.etat) === 1,
    ),
    getPath: (d) => d.path as any,
    getTimestamps: (d) => d.timestamps,
    getWidth: (d) => d.width + 1,
    widthUnits : 'pixels',
   
    rounded: true,
    trailLength: 400,
    currentTime: time_s1,
    fadeTrail: true
  });
  const trips_s2 = new TripsLayer<GouletSegmentFeature>({
    id: 'flux-2',
    data: visibleSegmentTrips.filter(
      (elt: GouletSegmentFeature) => Number(elt.properties.etat) === -1,
    ),
    getPath: (d) => d.path as any,
    getTimestamps: (d) => d.timestamps,
    getWidth: (d) => d.width,
    getColor: [249, 249, 249],
    opacity: 0.1,
    widthUnits : 'pixels',
    // rounded: true,
    trailLength: 400,
    currentTime: time_s1,
    fadeTrail: true
  });
  const trips_s3 = new CustomTripsLayer<GouletSegmentFeature>({
    id: 'flux-3',
    data: visibleSegmentTrips.filter(
      (elt: GouletSegmentFeature) => Number(elt.properties.etat) === 2,
    ),
    getPath: (d) => d.path as any,
    getTimestamps: (d) => d.timestamps,
    getColor: (d) => Number(d.properties.etat) === -1 ? [70, 70, 70] : [60, 179, 113],
    opacity: 0.5,
    getWidth: (d) => d.width + 1,
    widthUnits : 'pixels',
    rounded: true,
    trailLength: 400,
    currentTime: time_s2,
    fadeTrail: true,
  });
  const trips_s4 = new CustomTripsLayer<GouletSegmentFeature>({
    id: 'flux-4',
    data: visibleSegmentTrips.filter(
      (elt: GouletSegmentFeature) => Number(elt.properties.etat) === 3,
    ),
    getPath: (d) => d.path as any,
    getTimestamps: (d) => d.timestamps,
    getWidth: (d) => d.width + 1,
    widthUnits : 'pixels',
    rounded: true,
    trailLength: 400,
    currentTime: time_s3,
    fadeTrail: true,
  });
  const trips_s5 = new CustomTripsLayer<GouletSegmentFeature>({
    id: 'flux-5',
    data: visibleSegmentTrips.filter(
      (elt: GouletSegmentFeature) => Number(elt.properties.etat) === 4,
    ),
    getPath: (d) => d.path as any,
    getTimestamps: (d) => d.timestamps,
    getWidth: (d) => d.width + 1,
    widthUnits : 'pixels',
    rounded: true,
    trailLength: 400,
    currentTime: time_s4,
    fadeTrail: true,
  });

  React.useEffect(() => {
    animation.id = window.requestAnimationFrame(animate);
    return () => {
      window.cancelAnimationFrame(animation.id);
    };
  }, [animate, animation]);
  return [trips_s1, trips_s2, trips_s3, trips_s4, trips_s5];
};

export default useGouletCustomTripsLayer;
