import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  .icon {
    width: 1.3rem;
    height: 0.18rem;
    margin-right: 0.3rem;
  }
`;

interface ProductionItemProps {
  color: string;
  label: string;
}
const ProductionItem: React.FC<ProductionItemProps> = ({
  color,
  label,
}) => {
  return (
    <StyledDiv className="row pb-2 flex-nowrap align-items-center fs-6">
      <div className="col-auto">
        <div className="icon" style={{ background: color }}></div>
      </div>
      <div className="col-10 ps-1">{label}</div>
    </StyledDiv>
  );
};

export default ProductionItem;
