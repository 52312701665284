import { IconLayer } from '@deck.gl/layers/typed';

import {
  selectCurrentGouletZone,
  selectCurrentYear,
  selectGouletSymbols,
  selectStateClimate,
} from '../../../store/mainReducer';
import { useAppSelector } from '../../../store/hooks';
import { GouletSymbolFeature } from '../../../models/GouletSymbolFeature';
import { splitYear } from '../../../utils';

import B_2030_EJCS_echange_espagne from '../../../assets/images/symbols/B_2030_EJCS_echange_espagne.svg';
import B_2035_EJCS_echange_espagne from '../../../assets/images/symbols/B_2035_EJCS_echange_espagne.svg';
import B_2040_EJCS_echange_espagne from '../../../assets/images/symbols/B_2040_EJCS_echange_espagne.svg';
import C_2030_EJCS_echange_espagne from '../../../assets/images/symbols/C_2030_EJCS_echange_espagne.svg';
import C_2035_EJCS_echange_espagne from '../../../assets/images/symbols/C_2035_EJCS_echange_espagne.svg';
import C_2040_EJCS_echange_espagne from '../../../assets/images/symbols/C_2040_EJCS_echange_espagne.svg';
import C_2030_HNVC_echange_espagne from '../../../assets/images/symbols/C_2030_HNVC_echange_espagne.svg';
import C_2035_HNVC_echange_espagne from '../../../assets/images/symbols/C_2035_HNVC_echange_espagne.svg';
import C_2040_HNVC_echange_espagne from '../../../assets/images/symbols/C_2040_HNVC_echange_espagne.svg';
import E_2030_EJCS_echange_espagne from '../../../assets/images/symbols/E_2030_EJCS_echange_espagne.svg';
import E_2035_EJCS_echange_espagne from '../../../assets/images/symbols/E_2035_EJCS_echange_espagne.svg';
import E_2040_EJCS_echange_espagne from '../../../assets/images/symbols/E_2040_EJCS_echange_espagne.svg';
import E_2030_HNVC_echange_espagne from '../../../assets/images/symbols/E_2030_HNVC_echange_espagne.svg';
import E_2035_HNVC_echange_espagne from '../../../assets/images/symbols/E_2035_HNVC_echange_espagne.svg';
import E_2040_HNVC_echange_espagne from '../../../assets/images/symbols/E_2040_HNVC_echange_espagne.svg';

const useExchangeEspagneGouletLayer = () => {
  const currentYear = useAppSelector(selectCurrentYear);
  const currentGouletZone = useAppSelector(selectCurrentGouletZone);
  const currentClimat = useAppSelector(selectStateClimate);
  const gouletSymbol = useAppSelector(selectGouletSymbols);

  const getSymbolData = () => {
    return gouletSymbol.filter((item: GouletSymbolFeature) => {
      const { categorie } = item.properties;
      const mix = splitYear(currentYear).toString();
      const prefix = `${currentGouletZone}_${mix}_${currentClimat}_echange_espagne`;
      return categorie.startsWith(prefix);
    });
  };

  const getSymbolIcon = (categorie: string): any => {
    switch (categorie) {
      case 'B_2030_EJCS_echange_espagne':
        return {
          url: B_2030_EJCS_echange_espagne,
          width: 29,
          height: 34,
        };
      case 'B_2035_EJCS_echange_espagne':
        return {
          url: B_2035_EJCS_echange_espagne,
          width: 29,
          height: 34,
        };
      case 'B_2040_EJCS_echange_espagne':
        return {
          url: B_2040_EJCS_echange_espagne,
          width: 29,
          height: 34,
        };
      case 'C_2030_EJCS_echange_espagne':
        return {
          url: C_2030_EJCS_echange_espagne,
          width: 29,
          height: 34,
        };
      case 'C_2035_EJCS_echange_espagne':
        return {
          url: C_2035_EJCS_echange_espagne,
          width: 29,
          height: 34,
        };
      case 'C_2040_EJCS_echange_espagne':
        return {
          url: C_2040_EJCS_echange_espagne,
          width: 29,
          height: 34,
        };
      case 'C_2030_HNVC_echange_espagne':
        return {
          url: C_2030_HNVC_echange_espagne,
          width: 29,
          height: 34,
        };
      case 'C_2035_HNVC_echange_espagne':
        return {
          url: C_2035_HNVC_echange_espagne,
          width: 29,
          height: 34,
        };
      case 'C_2040_HNVC_echange_espagne':
        return {
          url: C_2040_HNVC_echange_espagne,
          width: 29,
          height: 34,
        };
      case 'E_2030_EJCS_echange_espagne':
        return {
          url: E_2030_EJCS_echange_espagne,
          width: 29,
          height: 34,
        };
      case 'E_2035_EJCS_echange_espagne':
        return {
          url: E_2035_EJCS_echange_espagne,
          width: 29,
          height: 34,
        };
      case 'E_2040_EJCS_echange_espagne':
        return {
          url: E_2040_EJCS_echange_espagne,
          width: 29,
          height: 34,
        };
      case 'E_2030_HNVC_echange_espagne':
        return {
          url: E_2030_HNVC_echange_espagne,
          width: 29,
          height: 34,
        };
      case 'E_2035_HNVC_echange_espagne':
        return {
          url: E_2035_HNVC_echange_espagne,
          width: 29,
          height: 34,
        };
      case 'E_2040_HNVC_echange_espagne':
        return {
          url: E_2040_HNVC_echange_espagne,
          width: 29,
          height: 34,
        };
      default:
        return;
    }
  };

  const getOffsetIcon = (categorie: string): any => {
    switch (categorie) {
      case 'B_2030_EJCS_echange_espagne':
        return [0,-2];
      case 'B_2035_EJCS_echange_espagne':
        return [0,-2];
      case 'B_2040_EJCS_echange_espagne':
        return [0,-2];

      case 'C_2030_EJCS_echange_espagne':
        return [0,-2];
      case 'C_2035_EJCS_echange_espagne':
        return [0,-2];
      case 'C_2040_EJCS_echange_espagne':
        return [0,-2];
      case 'C_2030_HNVC_echange_espagne':
        return [0,-2];
      case 'C_2035_HNVC_echange_espagne':
        return [0,-2];
      case 'C_2040_HNVC_echange_espagne':
        return [0,-2];

      case 'E_2030_EJCS_echange_espagne':
        return [0,-2];
      case 'E_2035_EJCS_echange_espagne':
        return [0,-2];
      case 'E_2040_EJCS_echange_espagne':
        return [0,-2];
      case 'E_2030_HNVC_echange_espagne':
        return [0,-2];
      case 'E_2035_HNVC_echange_espagne':
        return [0,-2];
      case 'E_2040_HNVC_echange_espagne':
        return [0,-2];
      default:
        return;
    }
  };

  const layer = new IconLayer({
    id: 'echange-espagne-layer',
    data: getSymbolData(),
    getIcon: (d: any) => getSymbolIcon(d.properties.categorie),
    getPosition: (d: any) => {
      return d.position;
    },
    getSize: (d: any) => getSymbolIcon(d.properties.categorie).height,
    getPixelOffset: (d: any) => getOffsetIcon(d.properties.categorie),
    getColor: () => [255, 255, 255, 255],
    pickable: true,
  });

  return [layer];
};

export default useExchangeEspagneGouletLayer;
