import styled from 'styled-components';

const StyledDiv = styled.div`
  &.right-area {
    position: relative;

  .close {
    position: absolute;
    top:10px;
    right: 10px;
    cursor: pointer;
  }
  .text {
  font-size: 14px;
  }
  .infos {
    background: rgba(10, 90, 114, 1);
     border-radius: 6px;
}
`;

const RightGouletComponent = () => {

  return (
    <StyledDiv className="right-area">
      <div style={{rowGap: "15px"}} className="row p-2">
      </div>
    </StyledDiv>
  );
};

export default RightGouletComponent;
